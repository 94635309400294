.rame {
  display: flex;
  border: 1px solid grayred;
  padding: 0px 30px;
  padding-bottom: 30px;
  background-color: rgba(128, 128, 128, 0.05);
  border-radius: 30px;
}
.orange {
  color: rgb(224, 165, 54);
}
